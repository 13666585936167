import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import styled, { ThemeProvider } from "styled-components"
import {
  Banner,
  BannerContent,
  BannerContentFullWidth,
  BannerText,
} from "src/components/Banner"
import SEO from "src/components/seo"
import "src/components/common/custom-bluma.scss"
import { PageTitle } from "src/components/Typography"
import { SectionTitle } from "src/components/PageSection"
import Modal from "react-modal"
import Logo from "src/components/Logo/Logo"
import closeIcon from "src/images/arrow-left.svg"
import closeIconDark from "src/images/arrow-left-dark.svg"

import left_arrow from "src/images/LeftArrow.svg"
import right_arrow from "src/images/RightArrow.svg"
import compare_image from "src/images/compare/rectangle.png"

import { ClientHeader } from "src/components/Header"
import Container from "src/components/Container"
import Button from "src/components/Button"
import { rem } from "src/utils/styling"
import { darkTheme } from "src/utils/themes"
import { LightFooter } from "src/components/Footer"
import Testimonials from "src/components/Testimonials"
import LandscapeComparison from "../components/LandscapeComparison"

Modal.setAppElement("#___gatsby")
const LeftArrow = styled.img`
  width: ${rem(35)}rem;
  vertical-align: middle;
  margin-right: ${rem(5)}rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(90)}rem;
    margin-right: ${rem(20)}rem;
  }
`

const RightArrow = styled.img`
  width: ${rem(20)}rem;
  vertical-align: middle;
  margin-left: ${rem(10)}rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(50)}rem;
    margin-left: ${rem(20)}rem;
  }
`

const PageSectionCustom = styled.div`
  background-color: #fbfeff;
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
  margin: auto;
  @media screen and (min-width: 1100px) {
    width: 1100px;
    padding: 0 30px;
  }
  @media screen and (min-width: 1400px) {
    width: 1240px;
  }
  @media screen and (min-width: 1600px) {
    width: 1400px;
  }
`

const Section = styled.div`
  text-align: center;
`

const SectionContent = styled.div`
  margin: ${rem(26)}rem;
  @media screen and (min-width: 1100px) {
    margin: ${rem(100)}rem;
  }
`

const SectionTitleSmaller = styled(SectionTitle)`
  color: #080f2a;
  margin: ${rem(30)}rem 0;
  text-align: center;
  strong {
    color: #00b8c5;
  }
  @media screen and (min-width: 1100px) {
    font-size: ${rem(42)}rem;
    text-align: center;
  }
`

const SectionText = styled.p`
  text-align: center;
  font-size: ${rem(16)}rem;
  font-weight: 500;
  @media screen and (min-width: 1100px) {
    margin: 0 ${rem(20)}rem;
    font-size: ${rem(22)}rem;
  }
`

const SectionButton = styled.div`
  display: none;
  @media screen and (min-width: 1100px) {
    display: block;
    text-align: center;
    margin: ${rem(50)}rem 0;
  }
`

const SectionTestimonial = styled.div`
  margin: 0 ${rem(20)}rem ${rem(150)}rem;
  @media screen and (max-width: 450px) {
    .slick-slider {
      .slick-dots {
        bottom: ${rem(55)}rem;
      }
    }

    .testimonial-footer-text-two {
      margin-bottom: ${rem(70)}rem;
    }
    .testimonial-footer-link-two {
      font-size: ${rem(18)}rem;
    }
  }
`

const HR = styled.hr`
  width: 250px;
  margin: ${rem(40)}rem auto ${rem(40)}rem;
  height: ${rem(1)}rem;
  background-color: #c4c4c4;
  @media screen and (min-width: 1100px) {
    width: 400px;
    margin: ${rem(80)}rem auto ${rem(80)}rem;
  }
`

const MobileSectionButton = styled.div`
  margin: ${rem(50)}rem 0 ${rem(180)}rem;
  position: relative;
  display: block;
  @media screen and (min-width: 1100px) {
    display: none;
  }
`

const MobileButton = styled.div`
  margin-top: ${rem(-175)}rem;
`

const MobileImage = styled.img`
  width: ${rem(280)}rem;
`

const customStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    marginRight: "0",
    border: 0,
    borderRadius: 0,
    zIndex: 5,
    padding: "1.5rem",
    overflow: "auto",
  },
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.5rem;
  background: ${prop => prop.theme.banner.background};
  color: ${prop => prop.theme.banner.color};
  height: 70px;
`

const LogoWrapper = styled.div`
  div {
    display: flex;
    justify-content: center;
    margin-top: 3px;
    @media screen and (min-width: 1100px) {
      display: block;
    }
  }
`

const MenuWrapper = styled.div`
  margin-top: 3rem;
  width: 1240px;
  padding: 0px 30px;
  button,
  a {
    display: block;
    padding: 0;
    margin: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
    outline: 0;
    border: 0;
    border-bottom: 1px solid
      ${prop => (prop.theme.logo == "light" ? "#88909C" : "#6A8897")};
    border-radius: 0;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    position: relative;
    color: inherit;
    background: transparent;
    &::after {
      content: " ";
      display: block;
      height: 16px;
      width: 10px;
      background: url(${prop =>
        prop.theme.logo == "light" ? closeIcon : closeIconDark});
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -8px;
      border: 0;
      outline: 0;
      cursor: pointer;
      transform: rotate(180deg);
    }
  }
`

const CloseButton = styled.button`
  display: block;
  height: 18px;
  width: 18px;
  background: url(${prop =>
    prop.theme.logo == "light" ? closeIcon : closeIconDark});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 26px;
  top: 26px;
  border: 0;
  outline: 0;
  cursor: pointer;
`

const DeskTopComparisonView = styled.div`
  display: none;
  @media screen and (min-width: 1100px) {
    display: block;
  }
`

const MobileComparisonView = styled.div`
  display: block;
  @media screen and (min-width: 1100px) {
    display: none;
  }
`

const Compare = ({ location }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <ThemeProvider theme={darkTheme}>
      <Banner>
        <ClientHeader />
        <Container>
          <SEO
            title="Competitive Landscape Comparison"
            description="While other platforms stop at talent curation, we commit to delivering quality code at scale. See how our services stack up to how other marketplaces work."
            location={location}
          />

          <BannerContent className="has-text-centered">
            <BannerContentFullWidth>
              <PageTitle>
                <LeftArrow
                  src={left_arrow}
                  alt="Out-task Stories"
                  className="arrow-image-left"
                />
                Why <em>Archimydes?</em>
                <RightArrow
                  src={right_arrow}
                  alt="Out-task Stories"
                  className="arrow-image-right"
                />
              </PageTitle>
              <BannerText>
                While other platforms stop at talent curation, we commit to
                delivering quality code at scale.
              </BannerText>
            </BannerContentFullWidth>
          </BannerContent>
        </Container>
      </Banner>
      <div>
        <PageSectionCustom>
          <Section>
            <SectionContent>
              <SectionTitleSmaller>
                Landscape <strong>Comparison</strong>
              </SectionTitleSmaller>
              <SectionText>
                Archimydes uses our proprietary methodology to deliver code
                directly to you more reliably and at a lower cost than any other
                outsourcing marketplace out there. Whether you’re a small
                business or a large enterprise, you can receive reliable code
                directly and enhance your engineering team
                <br />
                <br />
                See how our services stack up to how other marketplaces work:
              </SectionText>
              <SectionButton>
                <AnchorLink to="/compare#look-section">
                  <Button type="solid" color="secondary">
                    Take a look
                  </Button>
                </AnchorLink>
              </SectionButton>
              <MobileSectionButton>
                <MobileImage src={compare_image} alt="Comparison overview" />
                <MobileButton>
                  <a>
                    <Button
                      type="solid"
                      color="secondary"
                      style={{ padding: "15px 20px" }}
                      onClick={() => setIsOpen(true)}
                    >
                      Take a look
                    </Button>
                  </a>
                </MobileButton>
              </MobileSectionButton>
            </SectionContent>
          </Section>
          <Section>
            <DeskTopComparisonView id="look-section">
              <LandscapeComparison />
            </DeskTopComparisonView>
            <SectionButton>
              <AnchorLink to="/signup">
                <Button type="solid" color="secondary">
                  Get Started
                </Button>
              </AnchorLink>
            </SectionButton>
          </Section>
          <Section>
            <HR />
            <SectionTestimonial>
              <Testimonials name={"compare"} />
            </SectionTestimonial>
          </Section>
        </PageSectionCustom>
        <LightFooter />
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false)
        }}
        style={customStyles}
      >
        <Wrapper>
          <CloseButton
            onClick={() => {
              setIsOpen(false)
            }}
          ></CloseButton>
          <LogoWrapper>
            <Logo></Logo>
          </LogoWrapper>
        </Wrapper>
        <MenuWrapper>
          <MobileComparisonView>
            <LandscapeComparison />
          </MobileComparisonView>
        </MenuWrapper>
      </Modal>
    </ThemeProvider>
  )
}

export default Compare
