import React from "react"
import styled, { ThemeProvider } from "styled-components"
import "src/components/common/custom-bluma.scss"
import compare_left_arrow from "src/images/compare/left_arrow.svg"
import dash_icon from "src/images/compare/dash.svg"
import check_icon from "src/images/compare/check.svg"
import { rem } from "src/utils/styling"
import PropTypes from "prop-types"

const CompareContainer = styled.div`
  display: flex;
  padding-top: ${rem(40)}rem;
  margin-right: ${rem(20)}rem;
  @media screen and (min-width: 1100px) {
    display: flex;
    margin-bottom: ${rem(100)}rem;
  }
`

const CompareHR = styled.hr`
  width: 90%;
  margin: 0 auto;
`

const CompareContainerText = styled.div`
  flex: 1.2;
  margin: ${rem(100)}rem ${rem(20)}rem 0 0;
`

const CompareContainerChart = styled.div`
  flex: 2;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(3, 30, 45, 0.15);
  border-radius: ${rem(22)}rem;
  padding: ${rem(40)}rem ${rem(10)}rem;
`
const CompareContent = styled.div`
  margin: ${rem(45)}rem ${rem(20)}rem;
  @media screen and (min-width: 1100px) {
    margin: ${rem(75)}rem ${rem(20)}rem;
  }
`

const CompareContent20 = styled(CompareContent)`
  @media screen and (min-width: 1100px) {
    margin: ${rem(55)}rem ${rem(20)}rem;
  }
`
const CompareContent30 = styled(CompareContent)`
  @media screen and (min-width: 1100px) {
    margin: ${rem(-20)}rem ${rem(20)}rem ${rem(55)}rem;
  }
`
const SectionSubTitle = styled.h4`
  font-weight: bold;
  font-size: ${rem(18)}rem;
  color: #080f2a;
  text-align: left;
  margin-left: ${rem(30)}rem;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(28)}rem;
  }
`
const SectionSubText = styled.p`
  font-weight: 500;
  font-size: ${rem(16)}rem;
  color: #444444;
  text-align: left;
  margin-left: ${rem(30)}rem;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(20)}rem;
  }
`
const CompareLeftArrow = styled.img`
  width: ${rem(20)}rem;
  vertical-align: middle;
  margin-right: ${rem(10)}rem;
  margin-left: ${rem(-30)}rem;
`
const ChartContiner = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${rem(30)}rem;
`
const SubChartContiner = styled.div`
  display: flex;
  align-items: center;
  margin: ${rem(70)}rem 0;
  @media screen and (min-width: 1100px) {
    margin: ${rem(95)}rem 0;
  }
`
const ChartHeaderText = styled.h5`
  font-style: normal;
  font-weight: bold;
  font-size: ${rem(18)}rem;
  line-height: ${rem(32)}rem;
  text-align: center;
  color: #080f2a;
  text-align: center;
  flex: 1;
  line-height: 32px;
`
const SubChartText = styled.p`
  font-weight: 500;
  font-size: ${rem(18)}rem;
  line-height: ${rem(28)}rem;
  color: #444444;
  text-align: center;
  flex: 1;
`
const CompareIcon = styled.img`
  width: ${rem(35)}rem;
  vertical-align: middle;
`
export default function LandscapeComparison() {
  return (
    <CompareContainer>
      <CompareContainerText>
        <CompareContent>
          <SectionSubTitle>
            <CompareLeftArrow src={compare_left_arrow} alt="Compare" />
            Delivery Focus
          </SectionSubTitle>
          <SectionSubText>
            Instead of giving you developers to work with, we save you the
            hassle and deliver code directly that’s ready to merge with your
            codebase.
          </SectionSubText>
        </CompareContent>
        {/* <div style={{margin: '40px 0'}}></div> */}

        <CompareContent style={{ marginTop: -10 }}>
          <SectionSubTitle>
            <CompareLeftArrow src={compare_left_arrow} alt="Compare" />
            Story to code &lt;72 Hours
          </SectionSubTitle>
          <SectionSubText>
            We divide the work into small, manageable chunks so you can start
            merging code in as little as 72 hours.
          </SectionSubText>
        </CompareContent>

        <CompareContent>
          <SectionSubTitle>
            <CompareLeftArrow src={compare_left_arrow} alt="Compare" />
            User Story Driven Pricing
          </SectionSubTitle>
          <SectionSubText>
            We price our work based on user story complexity and time required,
            so you’re only paying for experts when it’s necessary.
          </SectionSubText>
        </CompareContent>

        <CompareContent>
          <SectionSubTitle>
            <CompareLeftArrow src={compare_left_arrow} alt="Compare" />
            Success Based Payments
          </SectionSubTitle>
          <SectionSubText>
            You don’t pay until we’ve fulfilled all of your requirements, so you
            can be sure that what we deliver is exactly what you ask for.
          </SectionSubText>
        </CompareContent>

        <CompareContent style={{ paddingTop: 10 }}>
          <SectionSubTitle>
            <CompareLeftArrow src={compare_left_arrow} alt="Compare" />
            Scale On Demand
          </SectionSubTitle>
          <SectionSubText>
            We can deliver more, or less, resources as needed. No need to have
            valuable resources waiting around for others on your dime.
          </SectionSubText>
        </CompareContent>

        <CompareContent30>
          <SectionSubTitle style={{ lineHeight: "16px" }}>
            <CompareLeftArrow src={compare_left_arrow} alt="Compare" />
            End-to-End Delivery Transparency
          </SectionSubTitle>
          <SectionSubText style={{ paddingTop: 15 }}>
            We document every action we take on our internal platform or via our
            Jira integration, so you can watch us work in real time.
          </SectionSubText>
        </CompareContent30>

        <CompareContent20>
          <SectionSubTitle>
            <CompareLeftArrow src={compare_left_arrow} alt="Compare" />
            Jira Integration
          </SectionSubTitle>
          <SectionSubText>
            We integrate directly into your Jira backlog, and you can manage and
            assign work directly from there as if Archimydes was another
            developer.
          </SectionSubText>
        </CompareContent20>

        <CompareContent20>
          <SectionSubTitle>
            <CompareLeftArrow src={compare_left_arrow} alt="Compare" />
            Curated Developers
          </SectionSubTitle>
          <SectionSubText>
            We vet the quality of developers and match them to the complexity of
            your user story in real-time, to make sure you get the quality you
            need upfront.
          </SectionSubText>
        </CompareContent20>

        <CompareContent style={{ marginTop: -10 }}>
          <SectionSubTitle>
            <CompareLeftArrow src={compare_left_arrow} alt="Compare" />
            Full-Service Delivery
          </SectionSubTitle>
          <SectionSubText>
            We provide user story writers, code reviewers, QA, and delivery
            integrations so you can focus on having all the big ideas.
          </SectionSubText>
        </CompareContent>
      </CompareContainerText>
      <CompareContainerChart>
        <ChartContiner>
          <ChartHeaderText>
            Managed Marketplace <br />
            (Archimydes)
          </ChartHeaderText>
          <ChartHeaderText>
            Curated Marketplaces <br />
            (Toptal, Gigster)
          </ChartHeaderText>
          <ChartHeaderText>
            Un-curated Marketplace <br />
            (UpWork, Freelancer)
          </ChartHeaderText>
        </ChartContiner>

        <SubChartContiner>
          <SubChartText>Code</SubChartText>
          <SubChartText>Developers</SubChartText>
          <SubChartText>Developers</SubChartText>
        </SubChartContiner>
        <CompareHR />

        <SubChartContiner>
          <SubChartText>
            <CompareIcon src={check_icon} alt="Check" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={dash_icon} alt="Dash" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={dash_icon} alt="Dash" />
          </SubChartText>
        </SubChartContiner>
        <CompareHR />

        <SubChartContiner>
          <SubChartText>
            <CompareIcon src={check_icon} alt="Check" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={dash_icon} alt="Dash" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={check_icon} alt="Check" />
          </SubChartText>
        </SubChartContiner>
        <CompareHR />

        <SubChartContiner>
          <SubChartText>
            <CompareIcon src={check_icon} alt="Check" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={dash_icon} alt="Dash" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={check_icon} alt="Check" />
          </SubChartText>
        </SubChartContiner>
        <CompareHR />

        <SubChartContiner>
          <SubChartText>
            <CompareIcon src={check_icon} alt="Check" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={dash_icon} alt="Dash" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={check_icon} alt="Check" />
          </SubChartText>
        </SubChartContiner>
        <CompareHR />

        <SubChartContiner>
          <SubChartText>
            <CompareIcon src={check_icon} alt="Check" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={dash_icon} alt="Dash" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={dash_icon} alt="Dash" />
          </SubChartText>
        </SubChartContiner>
        <CompareHR />

        <SubChartContiner>
          <SubChartText>
            <CompareIcon src={check_icon} alt="Check" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={dash_icon} alt="Dash" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={dash_icon} alt="Dash" />
          </SubChartText>
        </SubChartContiner>
        <CompareHR />

        <SubChartContiner>
          <SubChartText>
            <CompareIcon src={check_icon} alt="Check" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={check_icon} alt="Check" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={dash_icon} alt="Dash" />
          </SubChartText>
        </SubChartContiner>
        <CompareHR />

        <SubChartContiner>
          <SubChartText>
            <CompareIcon src={check_icon} alt="Check" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={dash_icon} alt="Dash" />
          </SubChartText>
          <SubChartText>
            <CompareIcon src={dash_icon} alt="Dash" />
          </SubChartText>
        </SubChartContiner>
      </CompareContainerChart>
    </CompareContainer>
  )
}
